import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import RouteWithLayout from '../RouteWithLayout';

const InspectPage = lazy(() => import('../../pages/inspect/index'));
const DefaultLayout = lazy(() => import('../layouts/Default/Index'));

const AdminRouter = () => (
    <Suspense fallback={<p>carregando...</p>}>
        <Switch>
            <RouteWithLayout
                component={InspectPage}
                exact={true}
                isPrivate={true}
                layout={DefaultLayout}
                path="/admin/inspect"
            />
        </Switch>
    </Suspense>
);

export default AdminRouter;
