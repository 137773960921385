export const palette = {
    primary: {
        light: '#58ABFF',
        main: '#00458B',
        dark: '#2479CF',
        contrastText: '#fff',
    },
    secondary: {
        light: '#EFFFFE',
        main: '#3FD2C7',
        dark: '#37c4b9',
        contrastText: '#fff',
    },
    gray: {
        light: '#EEEEEE',
        main: '#CCCCCC',
        dark: '#AAAAAA',
        contrastText: '#fff',
    },
    dark: {
        light: '#F8F8F8',
        main: '#666666',
        dark: '#263238',
        contrastText: '#fff',
    },
    dialogs: {
        sucess: '#2ECC71',
        warning: '#F1C40F',
        error: '#B84048',
    },
};

export const typography = {
    fontFamily: ['Source Sans Pro', 'verdana', 'sans-serif'].join(','),
};
