import { createGlobalStyle } from 'styled-components';

export const GlobalStyl = createGlobalStyle`
    html,
    body {
        padding: 0;
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        background-color: ${({ theme }) => theme.colors.dark.light};
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    * {
        box-sizing: border-box;
        margin:0px;
    }

    .c-pointer{
        cursor:pointer;
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .main-container{
        padding: 40px;
        width:95%;
        max-width: calc(100vw - 350px);
    }

    .marker-label{
        top: -25px;
        position: relative;
    }

    ${({ theme: { radius, colors, font } }) => `
        ${Object.keys(colors).reduce((concat, color) => {
            return `
                ${concat}
                ${Object.keys(colors[color]).reduce((acc, key) => {
                    return `
                            ${acc}
                            .text-${color}-${key} {
                                color: ${colors[color][key]};
                            }
                        `;
                }, '')}
        `;
        }, '')}

        ${Object.keys(font)
            .map(
                key => `
                .text-${key}{
                    font-size: ${font[key]};
                }
            `
            )
            .join('')}

        ${Object.keys(radius)
            .map(
                key => `
                .rounded-${key}{
                    border-radius: ${font[key]};
                }
            `
            )
            .join('')}
    `}

    .pointer{
        cursor:pointer
    }
`;

/*
    .text-primary-main
    .text-xs
    .rounded-xs
*/
