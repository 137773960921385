import { createTheme, ThemeProvider as MUITheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { palette, typography } from './customize';
import { GlobalStyl } from './GlobalStyl';

const _theme = createTheme({
    palette,
    typography,
    shape: {
        borderRadius: 2,
    },
});

export const theme = {
    colors: palette,
    font: {
        xs: '10px',
        sm: '13px',
        md: '16px',
        lg: '20px',
        xl: '25px',
    },
    radius: {
        xs: '5px',
        sm: '10px',
        md: '14px',
    },
    shadow: {
        custom: '0px 12px 20px #DEDEDE',
    },
    name: 'Default',
};

const Default = ({ children }) => (
    <>
        <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link
                href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;1,200&display=swap"
                rel="stylesheet"
            />
        </Helmet>
        <MUITheme theme={_theme}>
            <ThemeProvider theme={theme}>
                <GlobalStyl />
                {children}
            </ThemeProvider>
        </MUITheme>
    </>
);
export default Default;
