/* eslint-disable no-unused-vars */
export const Role = {
    ADMIN: 'ADMIN',
    GESTOR: 'GESTOR',
    CADASTRADOR: 'CADASTRADOR',
    LIDER_EQUIPE: 'LIDER_EQUIPE',
    FISCAL: 'FISCAL',
};

export const decodedToken = token => {
    const [header, payload, signature] = token.split('.');
    const decoded = JSON.parse(window.atob(payload));

    return {
        ...decoded,
        user: JSON.parse(decoded.sub),
    };
};

/*
    exp: 1649694965
    iat: 1649693165
*/
const AuthRole = jwt => {
    const { user, exp, iat } = decodedToken(jwt);

    return {
        user,
        jwt,
    };
};
export default AuthRole;
