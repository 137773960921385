import React, { createContext, useState } from 'react';

const Context = createContext(null);

function CategoryProvider({ children }) {
    const [category, setCategory] = useState({ value: 'GREEN_AREA' });
    return <Context.Provider value={{ category, setCategory }}>{children}</Context.Provider>;
}

export { Context, CategoryProvider };
