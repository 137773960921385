import { atom } from 'recoil';

export const location = atom({
    key: 'location',
    default: {
        position: {
            lat: -22.394673965805442,
            lng: -47.56302777232562,
            accuracy: 0,
            altitude: 0,
            altitudeAccuracy: 0,
            heading: 0,
            speed: 0,
        },
    },
});
