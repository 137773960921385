import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import Modal from '../Index';

const ModalOverlay = () =>
    sessionStorage.getItem('user') ? (
        <Modal open={true} onClose={console.log}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection={'column'}>
                <CircularProgress disableShrink />
                <Box ml={4}>Carregando...</Box>
            </Box>
        </Modal>
    ) : null;

export default ModalOverlay;
