import { Modal } from '@material-ui/core';
import styled from 'styled-components';

export const ModalStyl = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContentStyl = styled.div`
    background-color: ${({ bgNone }) => bgNone ? '' : '#fff'};
    padding: 20px;
    border-radius: ${({ theme }) => theme.radius.md};
    ${({ borderTop, theme }) =>
        borderTop ? `border-top: 15px solid ${theme.colors.secondary.main}` : ``};
    outline: none;
    user-select: none;
    min-width: 350px;
    max-width: 900px;
    margin: auto;
`;
