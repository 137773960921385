import { Redirect, Route } from 'react-router-dom';

const RouteWithLayout = ({
    layout: Layout,
    component: Component,
    isPrivate,
    ...rest
}) => (
    <Route
        {...rest}
        render={matchProps =>
            isPrivate && !sessionStorage.getItem('user') ? (
                <Redirect
                    to={{ pathname: '/login', state: { from: matchProps.location } }}
                />
            ) : (
                <Layout user={JSON.parse(sessionStorage.getItem('user'))}>
                    <Component
                        {...matchProps}
                        user={JSON.parse(sessionStorage.getItem('user'))}
                    />
                </Layout>
            )
        }
    />
);

export default RouteWithLayout;
