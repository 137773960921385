import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AdminRouter from './register/index';
import { useRecoilState } from 'recoil';

import { RefreshToken } from '../services/Login.service';
import { location as locationAtom } from '../store/location';
import steps from '../utils/steps'
import Theme from '../themes/index';

import { DrawerFilterProvider } from '../context/DrawerFilterProvider';
import { CategoryProvider } from '../context/CategoryProvider';

import Tour from 'reactour'

const Routes = () => (
    <BrowserRouter>
        <AdminRouter />
    </BrowserRouter>
);

const JSApp = ({ usercoords }) => {
    const [location, setLocation] = useRecoilState(locationAtom);
    const [tour, setTour] = useState(false)
    const [tourSteps, setTourSteps] = useState([])

    useEffect(() => {
        RefreshToken();
        const tourCount = Number(localStorage.getItem('@tour:menu-map'))
  
        const handleTour = ({ detail }) => {
            setTourSteps(steps[detail.step])
            if(tourCount < 2) {
                setTour(true);
                localStorage.setItem('@tour:menu-map', String(tourCount + 1))
            }
        }
        document.addEventListener('@trigger:tour', handleTour);
        return () => {
          document.removeEventListener('trigger:tour', handleTour);
        }
    }, []);
  
    useEffect(() => {
      if (usercoords) {
          setLocation({
              ...location,
              position: {
                  lat: usercoords.latitude,
                  lng: usercoords.longitude,
                  accuracy: usercoords.accuracy,
                  altitude: usercoords.altitude,
                  altitudeAccuracy: usercoords.altitudeAccuracy,
                  heading: usercoords.heading,
                  speed: usercoords.speed,
              },
          });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usercoords]);

    return (
        <Theme>
            <CategoryProvider>
                <DrawerFilterProvider>
                    <Routes />
                    <Tour
                        steps={tourSteps}
                        isOpen={tour}
                        onRequestClose={() => setTour(false)} 
                        accentColor="#00387C"
                    />
                </DrawerFilterProvider>
            </CategoryProvider>
        </Theme>
    )
}

export default JSApp;
