import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './ts/utils/date/index';
import GeolocationProvider, { GeoPositionProps } from './ts/providers/geolocation/index';
import UserContext from './ts/providers/usercontext/index';
import MapsApiLoader from './ts/providers/maps/api-loader/index';
import ToastProvider from './ts/providers/toast/index';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GeolocationProvider 
    render={({ position }: GeoPositionProps) => (
      <MapsApiLoader>
        <UserContext.Provider value={{ usercoords: position?.coords }}>
          <RecoilRoot>
            <App />
            <ToastProvider />
          </RecoilRoot>
        </UserContext.Provider>
      </MapsApiLoader>
    )}
  />
);
