import React, { createContext, useState, useContext, useEffect } from 'react';

const DrawerFilterContext = createContext(null);

const INITIAL_VALUES = {
  area_type: [],
  category: '',
  region_id: [],
  startDate: null,
  endDate: null,
};

function DrawerFilterProvider({ children }) {
  const [filters, setFilter] = useState({
    panel: { ...INITIAL_VALUES },
    regions: { ...INITIAL_VALUES },
    areas: { ...INITIAL_VALUES },
    points: { ...INITIAL_VALUES },
  });

  useEffect(() => {
    const storedFilters = JSON.parse(sessionStorage.getItem('@map:drawer:filters'));
    if (storedFilters) {
      setFilter(storedFilters);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('@map:drawer:filters', JSON.stringify(filters));
  }, [filters]);

  return (
    <DrawerFilterContext.Provider value={{ filters, setFilter }}>
      {children}
    </DrawerFilterContext.Provider>
  );
}

function useDrawerFilterProvider(namespace) {
  const { filters, setFilter } = useContext(DrawerFilterContext);

  const handleFilter = (data) => {
    setFilter((prevState) => ({
      ...prevState,
      [namespace]: {
        ...data,
      },
    }));
  };

  const handleClearFilters = () => {
    setFilter((prevState) => ({
      ...prevState,
      [namespace]: { ...INITIAL_VALUES },
    }));
  };

  return { filters: filters[namespace], setFilter: handleFilter, clearFilters: handleClearFilters };
}

export { DrawerFilterProvider, useDrawerFilterProvider };
