import React, { useContext } from 'react';
import UserContext from './ts/providers/usercontext/index';
import DefaultTheme from './ts/themes/default'
import JSRoutes from './js/routes/index';
import Routes from './ts/routes/index'

function App() {
  const { usercoords } = useContext(UserContext);

  return (
    <>
      <JSRoutes usercoords={usercoords} />
      <DefaultTheme>
        <Routes />
      </DefaultTheme>
    </>
  );
}

export default App;
