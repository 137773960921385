import AuthRole, { Role } from '../utils/AuthRole.util';
import { Http } from '../utils/Http.util';

export const RefreshToken = async () => {
    try {
        if (window.sessionStorage.getItem('token')) {
            const { data } = await Http.get(`/v1/auth/refresh_token`);
            if (data.token) {
                window.sessionStorage.setItem('token', data.token);
            }
        }
    } catch (error) {
        console.log(error);
    } finally {
        setTimeout(RefreshToken, 1000 * 60 * 15);
    }
};

export const doLogin = async ({ login, password }) => {
    const { data } = await Http.post(`/v1/auth/login`, {
        username: login,
        password,
    });

    const decoded = AuthRole(data.token);

    if (decoded?.user?.profile?.code === Role.ADMIN) {
        window.sessionStorage.setItem('user', JSON.stringify(decoded.user));
        window.sessionStorage.setItem('token', data.token);
        return data;
    } else throw new Error('Você não possui acesso administrativo');
};
