import { Backdrop } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { ModalContentStyl, ModalStyl } from './Modal.styles';

const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block',
            padding: 30,
        },
    })
);

const ModalComponent = ({ children, open, onClose, borderTop = false, bgNone }) => {
    const classes = useStyles();

    return (
        <ModalStyl
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={classes.modal}
        >
            <ModalContentStyl borderTop={borderTop} bgNone={bgNone}>{children}</ModalContentStyl>
        </ModalStyl>
    );
};

export const ModalNavigation = ({ render }) => {
    const [navigationState, setState] = useState(0);

    return render[navigationState]({
        navigationState, 
        prevState: () => {
            if(navigationState !== 0){
                setState( navigationState - 1 )
            }
        },
        nextState: () => {
            if((render.length - 1) > navigationState ){
                setState( navigationState + 1 ) 
            }
        }
    });
};


export default ModalComponent;
