/* eslint-disable @typescript-eslint/no-unused-vars */
import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { ChakraProvider } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import React, { ReactNode } from 'react';

const themeOverride = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  styles: {
    styles: {
      global: (props: any) => ({
        'html, body': {
          padding: 0,
          margin: 0,
          fontFamily: "'Source Sans Pro', sans-serif",
          backgroundColor: mode('light', 'dark')(props),
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
        '*': {
          boxSizing: 'border-box',
          margin: 0,
        },
        '.c-pointer': {
          cursor: 'pointer',
        },
        '.truncate': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '.main-container': {
          padding: '40px',
          width: '95%',
          maxWidth: 'calc(100vw - 350px)',
        },
        '.marker-label': {
          top: '-25px',
          position: 'relative',
        },
        '.pointer': {
          cursor: 'pointer',
        },
        ...Object.keys(props.theme.colors).reduce((acc: Record<string, any>, color) => {
          Object.keys(props.theme.colors[color]).forEach(key => {
            acc[`.text-${color}-${key}`] = {
              color: props.theme.colors[color][key],
            };
          });
          return acc;
        }, {}),
        ...Object.keys(props.theme.fontSizes).reduce((acc: Record<string, any>, key) => {
          acc[`.text-${key}`] = {
            fontSize: props.theme.fontSizes[key],
          };
          return acc;
        }, {}),
        ...Object.keys(props.theme.radii).reduce((acc: Record<string, any>, key) => {
          acc[`.rounded-${key}`] = {
            borderRadius: props.theme.radii[key],
          };
          return acc;
        }, {}),
      }),
    },
  },
});

const Theme = ({ children }: { children: ReactNode }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;1,200&display=swap" rel="stylesheet" />
    </Helmet>

    {/* <ChakraProvider theme={themeOverride}> */}
      <>
        {children}
      </>
    {/* </ChakraProvider> */}
  </>
);

export default Theme;
