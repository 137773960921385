import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AdminRouter from './register/index';

const Routes = () => (
    <BrowserRouter>
        <AdminRouter />
    </BrowserRouter>
);

export default Routes;
