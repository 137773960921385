import { createContext } from 'react';

import { Coords } from '../../providers/geolocation/index';

export type UserContextType = {
  usercoords: Coords
};

const defaultAuthContext: UserContextType = {
  usercoords: {
    accuracy: 0,
    latitude: 0,
    longitude: 0,
    altitude: 0,
    altitudeAccuracy: 0,
    heading: 0,
    speed: 0,
  }
};

const UserContext = createContext<UserContextType>(defaultAuthContext);
export default UserContext;