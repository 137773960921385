import React, { ReactNode } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

interface MapProps {
  children: ReactNode;
}

const MapsApiLoader = ({ children }: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'script-loader',
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['drawing', 'places'],
    language:"pt-br",
    region:"us",
  })

  return isLoaded ? <>{children}</> : null;
}

export default MapsApiLoader;
