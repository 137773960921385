/* eslint-disable no-unused-vars */
import Axios from 'axios';
import Qs from 'qs';

const Http = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

const HttpMock = Axios.create({
    baseURL: process.env.REACT_APP_API_MOCK_URL,
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

const handleConfig = config => {
    config.headers.common['platform-origin'] = `Web`;
    config.headers.common['Authorization'] = `Bearer ${window.sessionStorage.getItem(
        'token'
    )}`;
    return config;
};

/*
    Padrão de tratamento de erros:
    {
        "errors": [
            "field": "field required"
        ],
        "detail": "some error"
    }
*/
const handleErrors = error => {
    const { detail } = error?.response?.data ?? {};

    switch (error?.response?.status) {
        case 400:
            error.message = 'Ocorreu um erro ao realizar a operação desejada.';
            break;

        case 401:
            error.message = 'Você não tem permissão para acessar esse recurso.';
            break;

        case 403:
            error.message = 'Sua sessão expirou. Realize login novamente.';
            setTimeout(() => {
                window.sessionStorage.removeItem('user');
                window.sessionStorage.removeItem('token');
                window.location.href = '/login';
            }, 3000);
            break;

        case 404:
            error.message = 'Não foi possível encontrar o recurso desejado.';
            break;

        case 409:
            error.message = 'Ocorreu um conflito, modifique os dados e tente novamente';
            break;

        case 500:
            error.message = 'Ocorreu um erro ao realizar a operação desejada.';
            break;

        default:
            error.message = 'Ocorreu um erro ao realizar a operação desejada.';
            console.log(error?.response?.status);
            break;
    }

    return Promise.reject(error);
};

HttpMock.interceptors.request.use(handleConfig, Promise.reject);
HttpMock.interceptors.response.use(config => config, handleErrors);

Http.interceptors.request.use(handleConfig, Promise.reject);
Http.interceptors.response.use(config => config, handleErrors);

export { Http, HttpMock };
