/* eslint-disable import/no-anonymous-default-export */

export default {
    panel: [
        {
            selector: '',
            content: 'Olá! Estamos entusiasmados em apresentar algumas atualizações importantes no nosso sistema. Para melhorar sua experiência fizemos as seguintes adições',
        },
        {
            selector: '.new-menu-map-IMPORT',
            content: 'Este botão permite que você importe arquivos KML para o mapa. Use-o para carregar dados geográficos de forma rápida e fácil. Clique aqui para começar a importar seus arquivos KML e visualizá-los diretamente no mapa',
        },
        {
            selector: '.new-menu-map-AREA',
            content: 'Com este botão, você pode cadastrar novas áreas no mapa. É ideal para demarcar regiões específicas de interesse. Clique aqui para começar a definir e salvar áreas diretamente no mapa',
        },
        {
            selector: '.new-menu-map-POINT',
            content: 'Este botão permite que você cadastre novos pontos no mapa. Perfeito para marcar locais específicos, como pontos de interesse ou referências geográficas. Clique aqui para adicionar e salvar pontos diretamente no mapa',
        },
    ]
}
