import Geolocation from 'react-geolocation';

export type Coords = {
    accuracy: number;
    latitude: number;
    longitude: number;
    altitude: number;
    altitudeAccuracy: number;
    heading: number;
    speed: number;
}

export type GeoPositionProps = {
    position: {
      timestamp: number;
      coords: Coords
    }
}

export default Geolocation;
