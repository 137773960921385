import { ToastContainer, toast, ToastOptions  } from 'react-toastify';

export const useToast = () => {
  const _config:ToastOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  return {
    warn: (message: string) => toast.warn(message, _config),
    info: (message: string) => toast.info(message, _config),
    success: (message: string) => toast.success(message, _config),
    error: (message: string) => toast.error(message, _config),
  }
};

export default ToastContainer;
